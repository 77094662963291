import React from 'react'
import _ from 'lodash'
import { FormattedHTMLMessage } from 'react-intl'
import Img from 'gatsby-image'
import { Row } from 'react-styled-flexboxgrid'
import { useStaticQuery, graphql } from 'gatsby'

import { TitleCard } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import Layout from '../components/Layout'

import image_background from '../images/blog_background.jpg'

const Philosophy = props => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "philosophy.png" }) {
        childImageSharp {
          fixed(height: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  console.log('FILE', file)
  return (
    <Layout
      title="Filosofía"
      description="En 1991 fundamos Arreguín Ruiz Sánchez Cabrera y Asociados S.A. de C.V (AS Consulting Group) con la finalidad de proveer soluciones contables y cumplir con las nuevas obligaciones fiscales"
      {...props}
    >
      <SectionHeaderImage image={image_background} position="0" />
      <Section height="650px">
        <div
          style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
        >
          <TitleCard forwardedAs="h1">
            <FormattedHTMLMessage id="philosophy.title" />
          </TitleCard>
          <p>
            <FormattedHTMLMessage id="philosophy.description" />
          </p>
          <ul>
            {_.times(7, Number).map(i => (
              <li key={i}>
                <FormattedHTMLMessage id={`philosophy.list1.item${i + 1}`} />
              </li>
            ))}
          </ul>
        </div>
        <Row center="xs">
          <Img
            alt="Filosofía"
            fixed={file.childImageSharp.fixed}
            objectFit="contain"
          />
        </Row>
      </Section>
    </Layout>
  )
}

export default Philosophy
